import Router from 'next/router';
import { FunctionComponent, useEffect } from 'react';
import withAuth from '../auth/withAuth';

const HomePage: FunctionComponent = () => {
  useEffect(() => {
    Router.replace('/anaxi/workspaces');
  }, []);

  return null;
};

export default withAuth(HomePage);
