import { FunctionComponent, ReactNode, useContext } from 'react';
import { Loader } from '../components/layout/loader';
import { UserContext } from '../contexts/user';

function withAuth<P extends object & { getLayout?(): ReactNode }>(
  InnerComponent: FunctionComponent<P>
): FunctionComponent<P> {
  type AuthComponentType = React.FC<P> & {
    getLayout?: P['getLayout'];
  };

  const AuthComponent: AuthComponentType = (props) => {
    const { user } = useContext(UserContext);

    const isLogged = !!user?.id;

    if (!isLogged) {
      return <RedirectTo uri="/login" />;
    }

    return <InnerComponent {...props} />;
  };

  // Forward static properties
  AuthComponent.getLayout = (InnerComponent as AuthComponentType).getLayout;

  return AuthComponent;
}

const RedirectTo: FunctionComponent<{ uri: string }> = ({ uri }) => {
  window.location.assign(uri);
  return <Loader />;
};

export default withAuth;
